import { Wrapper } from '../styled';
import { SectionHeader, FeaturedWrapper, FeaturedImage } from './styled';
import { listMedia } from '../constants';

function Featured() {
  return (
    <Wrapper align="center">
      <SectionHeader>As Featured in</SectionHeader>
      <FeaturedWrapper>
        {listMedia.map((media) => (
          <a href={media.url} target="_blank" rel="noopener noreferrer">
            <FeaturedImage
              height={media.height}
              src={media.image}
              alt={media.name}
            />
          </a>
        ))}
      </FeaturedWrapper>
    </Wrapper>
  );
}

export default Featured;
